import React from "react";
import NotFoundImage from "./notfound.svg";

const NotFound = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <img
        src={NotFoundImage}
        alt="Not Found"
        style={{ width: "25%", marginBottom: "20px" }}
      />

      <h1>Oops! Page Not Found</h1>
      <p>The page you are looking for does not exist.</p>
    </div>
  );
};

export default NotFound;
