import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import icon from "../assets/images/logo.png";

function NavBar() {
  return (
    <Navbar expand="lg">
      <Container>
        <img
          src={icon}
          width="30"
          height="30"
          className="d-inline-block align-top"
          alt="Picky logo"
          style={{ marginRight: "10px" }}
        />
        <Navbar.Brand href="/"> PICKY</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            <Nav.Link href="/" style={{ fontFamily: "Medium" }}>
              Home
            </Nav.Link>
            <Nav.Link href="/terms" style={{ fontFamily: "Medium" }}>
              Term of services
            </Nav.Link>
            <Nav.Link href="/privacypolicy" style={{ fontFamily: "Medium" }}>
              Privacy
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
