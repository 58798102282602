import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import background from "../assets/images/bg.svg";
import icon from "../assets/images/brandimg.svg";
import qrCode from "../assets/images/qr-code.jpg";
import NavBar from "../nav/NavBar";
import Image from "react-bootstrap/Image";
const HomePage = () => {
  const appStoreUrl =
    "https://apps.apple.com/us/app/picky-fun-image-poll/id6448685587?itsct=apps_box_link&itscg=30200";
  const playStoreUrl =
    "https://play.google.com/store/apps/details?id=com.picky.picky";

  const headingStyle = {
    fontFamily: "Sunborn",
    textAlign: "start",
    fontSize: "max(4.2vw, 30px)",
    textShadow: "2px 0.5px 10px #a6a6a6",
  };

  const buttonAppleStyle = {
    width: "150px",
    margin: "10px",
    marginRight: "0px",
    marginLeft: "0px",
  };

  const buttonGoogleStyle = {
    width: "155px",
  };

  const bioStyle = {
    width: "min(44vw)",
    minWidth: "100%",
    fontFamily: "Medium",
    textAlign: "start",
    fontSize: "max(1.3vw, 18px)",
  };

  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        width: "100%",
        height: "120%",
        margin: "0px",
        padding: "0px",
      }}
    >
      <Row style={{ padding: "4vw" }}>
        <Col
          xs={{ span: 12, order: 2 }}
          sm={{ span: "auto", order: 1 }}
          md={{ span: "auto", order: 1 }}
          lg={{ span: "auto", order: 1 }}
          style={{
            textAlign: "start",
            paddingTop: "6vw",
          }}
        >
          {" "}
          <div>
            <h1 style={headingStyle}>
              {" "}
              <b style={{ color: "#FFFBEF" }}>DONT'T BE</b>{" "}
              <b style={{ color: "#8C52FF" }}> INDECISIVE, </b>
            </h1>
            <h1 style={headingStyle}>
              {" "}
              <b style={{ color: "#FFFBEF" }}>BE</b>{" "}
              <b style={{ color: "#8C52FF" }}>PICKY</b>{" "}
            </h1>
            <p style={bioStyle}>
              Save your time on decision making and have some fun! Whether it be
              choosing photos to upload on social media or your OOTD, Picky is
              here to help. Download now to be a part of our fun-loving
              community.
            </p>
            <Row>
              <a
                href={appStoreUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={buttonAppleStyle}
              >
                <img
                  src="https://linkmaker.itunes.apple.com/assets/shared/badges/en-gb/appstore-lrg.svg"
                  alt="Download on the App Store"
                />
              </a>
              <a
                href={playStoreUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={buttonGoogleStyle}
              >
                <img
                  src="https://play.google.com/intl/en_gb/badges/static/images/badges/en_badge_web_generic.png"
                  alt="Get it on Google Play"
                  style={buttonGoogleStyle}
                />
              </a>
            </Row>
            <img
              src={qrCode}
              alt="QR Code"
              style={{ maxWidth: "295px", height: "auto" }}
            />
          </div>
        </Col>
        <Col
          xs={{ span: 11, order: 1 }}
          sm={{ span: 5, order: 2 }}
          md={{ span: 5, order: 2 }}
          lg={{ span: 5, order: 2 }}
        >
          <Image src={icon} style={{ width: "130%" }} />
        </Col>
      </Row>
    </div>
  );
};

export default HomePage;
