import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
const Footer = () => {
  return (
    <div
      style={{
        position: "relative",
        fontFamily: "medium",
        fontSize: "11px",
      }}
    >
      <Row style={{ padding: "20px" }}>
        <p> @Copyright Picky 2022 - All Right Reserved. </p>
      </Row>
    </div>
  );
};

export default Footer;
