import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import HomePage from "./homepage/HomePage";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Privacy from "./privacy/Privacy";
import Terms from "./terms/Terms";
import NavBar from "./nav/NavBar";
import Footer from "./footer/Footer";
import NotFound from "./notfound/NotFound";

function App() {
  return (
    <div>
      <NavBar />
      <Router>
        <div className="App">
          <Routes>
            <Route exact path="/" element={<HomePage />}></Route>
            <Route exact path="/privacypolicy" element={<Privacy />}></Route>
            <Route exact path="/terms" element={<Terms />}></Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
